<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        class="menumonth"
        min-width="270px"
        top
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            :value="formatDate"
            :placeholder="nextMonth"
            outlined
            readonly
            dense
            v-on="on"
            ><template slot="prepend-inner">
              <v-icon v-on="on">mdi-calendar</v-icon>
            </template></v-text-field
          >
        </template>
        <template v-if="inFilter">
          <div class=" menumonth px-2 test2 ">     
            <v-switch
                                    
                    label="By Range"  
                     dense                 
                   v-model="forRange"
                   :disabled="date&&date.length>2"
                  >          
                 
                  </v-switch>      
                  <template v-if="date&&date.length>2">
                    <v-alert class="menumonth" dense outlined type="info">By range only 2 dates</v-alert>
                    </template> 
                    <template v-else-if="date&&date.length<2&&forRange">
                      <v-alert class="menumonth" dense outlined type="info">Must have 2 dates</v-alert>
                    </template>
         
                  
        </div>
       
        </template>
   
        <v-date-picker
          v-model="date"
          type="month"
          no-title
          scrollable           
          :min="minDate"
          :multiple="isMultiple"
        >
        <template v-if="inFilter">
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="cancelMenu"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            :disabled="date==null||(date.length<2&&forRange)"
            color="primary"
          @click="getmonth"
          >
            OK
          </v-btn>
        </template>
        
        </v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>
<script>
import { get } from "lodash";
import moment from "moment";
export default {
  props: {
    inFilter: Boolean,
    isMultiple: Boolean,
    monthToUpdate:String
  },
  data() {
    return {
      date: null,
      menu: false,
      forRange:false,
    };
  },
  computed: {
    minDate() {
      if (!this.inFilter) {
        return moment().endOf("month").toISOString().substr(0, 10);
      }
      return "01-1950";
    },
    formatDate() {
      if (this.date != "" && this.date != null && this.date != undefined) {
        if (!this.isMultiple) {
          const [year, month] = this.date.split("-");
          return `${month}-${year}`;
        }
        return this.date

      }

      return "";
    },
    nextMonth() {
      if (this.inFilter) {
        return "01-1950";
      } else {
        const t = moment().add("month", 1).startOf("month").toISOString();

        const [year, month, day] = t.split("-");
        return `${month}-${year}`;
      }
    },
  },
  watch:{
    date:{
      handler(val){       
        if (val!=null) {
          if (!this.inFilter) {
          this.getmonth(val)
        }else{         
          if (val.length>2) {
            this.forRange=false
          }
        }
        }
       
      }
    },
    monthToUpdate(val){
      if (val!=null&&this.date==null) {
        const [year,month,day]=val.split('-')
        this.date= `${year}-${month}`
     
      }
    }
  },
  methods: {
    getmonth(date) {   
      if (!this.inFilter) {
        this.date = date;
      const dt = moment(date).startOf('month').toISOString();      
      this.$emit("monthPick", dt); 
      this.menu=false;
      }else{
        if (this.forRange) {
        const x=  this.rangeMonths(this.date[0],this.date[1]);
        this.$emit("monthPick", x); 
        this.menu=false;
        }else{
          const dts = this.date.map(dm=>{
            return moment(dm).startOf('month').toISOString()
          })
          this.$emit("monthPick", dts); 
        this.menu=false;
        }
       
      }
      
    },

    cancelMenu(){
      this.date=null;
      this.menu=false;
      this.forRange=false
    },

    rangeMonths(date1,date2){
      let month1 = moment(date1).startOf('month');
      let month2 = moment(date2).startOf('month');
      let months = [];
      while (month1.isBefore(month2||month1.isSame(month2))) {
        months.push(month1.toISOString());
        month1.add(1,'month')
      }
      months.push(month2.toISOString())
      return months
    }
  },
};
</script>
<style lang="scss" scoped>
.menumonth{
  background-color: white !important;
}
</style>
